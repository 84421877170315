<!--
 * @Author: your name
 * @Date: 2022-04-07 09:22:38
 * @LastEditTime: 2022-04-11 16:52:44
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\components\mealbox\index.vue
-->
<template>



  <div v-if="give&&item.give>0&&flag" class="item2" :class="{selitem2:item.id == active?true:false}" @click="selectitem">
      <div class="give">充{{Math.trunc(item.money)}}得{{Math.trunc(item.money)+Math.trunc(item.give)}}</div>
  <span class="span">{{Math.trunc(item.money)}}</span>
  元</div>
  <div v-else class="item" :class="{selitem:item.id == active?true:false}" @click="selectitem">
  <span class="span">{{Math.trunc(item.money)}}</span>
  元</div>
 


</template>
<script>
export default {
  data() {
    return {

    }
  },
  props:{
    item:{
      typeof:Object,
      default:()=>{
        return {}
      }
    },
    active:{
      type:Number
    },
    give:{
      type:Boolean,
      default:true
    },
     giveNumber:{
      type:Number,
    },
    flag:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    selectitem(){
      this.$emit("selectitem",this.item)
    }
  }
}
</script>
<style lang="less" scoped>
.item{
    position: relative;
    width: 105px;
    height: 54px;
    border-radius: 6px;
    border: solid #cecece 1px;
    text-align: center;
    line-height: 54px;
    color: #333333;
    font-size: 16px;
    margin-bottom: 15px;

  
      .span{
      font-family: "bahn";
    }
}

.item2{
    position: relative;
    width: 107px;
    height: 56px;
    border-radius: 6px;
    // border: solid #cecece 1px;
    text-align: center;
    line-height: 54px;
    color: #333333;
    font-size: 16px;
    margin-bottom: 15px;
    background: url('~@/assets/images/selectpack/giveA.png') no-repeat;
    background-size: 100% 100%;

    .give {
      width: 100%;
      position: absolute;
      top: -16px;
      font-size: 13px;
      color: #fff;
      text-align: center;
    }
      .span{
      font-family: "bahn";
    }
}
.item:nth-child(1),.item:nth-child(2),.item:nth-child(4),.item:nth-child(5){
  margin-right: 10px; 
}

.item2:nth-child(1),.item2:nth-child(2),.item2:nth-child(4),.item2:nth-child(5){
  margin-right: 10px; 
}
.selitem{
  // background-color: #d3ac2a;
  width: 107px;
  height: 56px;
  color: #fe5f37;
  background: url('~@/assets/images/selectpack/dui.png') no-repeat;
  background-size: 100% 100%;
  border:none;
}

.selitem2{
  // background-color: #d3ac2a;
  width: 107px;
  height: 56px;
  color: #fe5f37;
  background: url('~@/assets/images/selectpack/give.png') no-repeat;
  background-size: 100% 100%;
  border:none;
}
</style>